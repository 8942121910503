import { Provider as AuthProvider } from "next-auth/client";
import { AppProps } from "next/app";
import React from "react";
import "../styles/globals.css";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AuthProvider
      session={pageProps.session}
      options={{
        clientMaxAge: 12 * 60 * 60,
        keepAlive: 3 * 60,
      }}>
      <Component {...pageProps} />
    </AuthProvider>
  );
}

export default MyApp;
